import { css } from "@emotion/react";
import { breakpoints } from "shared";

const container = css``;

const image = css`
  width: 100%;
  height: 400px;
  overflow: hidden;

  img {
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  @media ${breakpoints.fromMed} {
    width: unset;
    height: unset;

    img {
      width: 400px;
      height: 400px;
    }
  }
`;

const title = css`
  margin-top: 20px;
`;

const metadata = css`
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);

  p {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 8px 0;
    color: rgba(0, 0, 0, 0.9);
  }
`;

export default { container, image, metadata, title };

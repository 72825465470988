import { css } from "@emotion/react";
import { breakpoints } from "shared/styles";

const mobile = css`
  display: flex;
  justify-content: flex-end;

  button {
    padding: 0;
  }

  @media ${breakpoints.fromSml} {
    display: none;
  }
`;

const desktop = css`
  display: none;

  @media ${breakpoints.fromSml} {
    display: flex;
    width: 120px;
    justify-content: space-evenly;
    align-items: center;
    justify-self: flex-end;
  }
`;

export default { mobile, desktop };

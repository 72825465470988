import { css } from "@emotion/react";

const container = css`
  padding: 40px 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 80px;
`;

const caption = css`
  text-align: center;
  background-color: white;
  p {
    margin-bottom: 20px;
  }
`;

const social = css`
  width: 120px;
  margin: 80px auto 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const info = css`
  a,
  p {
    display: block;
    color: rgba(0, 0, 0, 0.4);
  }
  a:first-of-type {
    margin-top: 8px;
  }
  a:hover {
    color: black;
  }
  text-align: center;
`;

export default { container, social, caption, info };

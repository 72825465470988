import styles from "./Header.styles";
import { Navigation } from "../navigation";
import React from "react";

export const Header: React.FC = () => {
  return (
    <header css={styles.container}>
      <div css={styles.heading}>
        <img
          src="/icons/logo.png"
          height={40}
          width={40}
          alt="logo"
          loading="eager"
        />
        <h1>Udruga &bdquo;4 lista&rdquo;</h1>
      </div>
      <Navigation />
    </header>
  );
};

import styles from "./Navigation.styles";
import { NavigationMobile } from "./fragments/NavigationMobile";
import React from "react";

export const Navigation: React.FC = () => {
  return (
    <>
      <div css={styles.mobile}>
        <NavigationMobile />
      </div>
      <div css={styles.desktop}>
        <a
          href="https://www.instagram.com/udruga_4lista/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="https://www.instagram.com/udruga_4lista/"
            src="icons/instagram.svg"
            height={24}
            width={24}
            loading="eager"
          />
        </a>
        <a
          href="https://www.facebook.com/udruga4lista"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="https://www.facebook.com/udruga4lista"
            src="icons/facebook.svg"
            height={24}
            width={24}
            loading="eager"
          />
        </a>

        <a
          href="mailto:udruga.4lista@gmail.com"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="udruga.4lista@gmail.com"
            src="icons/email.svg"
            height={24}
            width={24}
            loading="eager"
          />
        </a>
        <a href="tel:+385953817277" target="_blank" rel="noreferrer">
          <img
            alt="+385953817277"
            src="icons/phone.svg"
            height={20}
            width={20}
            loading="eager"
          />
        </a>
      </div>
    </>
  );
};

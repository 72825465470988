import { css } from "@emotion/react";
import { breakpoints } from "shared/styles"

const container = css`
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;

  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const heading = css`
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
  
  @media ${breakpoints.fromMed} {
    img {
      margin-right: 20px;
    }
  }
`;

export default { container, heading };

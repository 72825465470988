import { css } from "@emotion/react";

const popup = css`
  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  padding: 40px 0px;
  animation: fadein 0.15s ease-in-out;
  filter: drop-shadow(-20px -20px 20px 10px);
`;

const popupHeader = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const heading = css`
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
`;

const popupContent = css`
  margin-top: 40px;
  background-color: white;
  z-index: 1;
  padding: 20px 0;
`;

const navigationItem = css`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  img {
    width: 24px;
    margin-right: 8px;
  }
`;

export default { popup, popupHeader, heading, popupContent, navigationItem };

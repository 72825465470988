import styles from "./Home.styles";
import React from "react";
import { Header, Footer, Donate } from "shared";
import { ServiceList } from "./fragments/service-list";
import { MemberList } from "./fragments/member-list";
import { Gallery } from "./fragments/gallery";

export const Home: React.FC = () => {
	return (
		<div>
			<Header />
			<div css={styles.quote}>
				<h3>
					Terapija i rehabilitacija djece i osoba s poteškoćama u razvoju pomoću
					konja.
				</h3>
			</div>
			<div css={styles.hero}>
				<h4>"Konji nam posuđuju krila koja mi nemamo." &mdash; Pam Brown</h4>
			</div>

			<div css={styles.rest}>
				<ServiceList />
				<Donate />
				<MemberList />
				<Gallery />
				<Footer />
			</div>
		</div>
	);
};

import { css } from "@emotion/react";
import { breakpoints } from "shared/styles";

const container = css`
  background-color: rgba(0, 0, 0, 0.03);
  padding: 100px 40px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  column-gap: 20px;

  @media ${breakpoints.fromLrg} {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
`;

const caption = css`
  p:not(:last-child) {
    margin-bottom: 20px;
  }

  @media ${breakpoints.fromLrg} {
    width: 40%;
    max-width: 420px;
  }
`;

const heading = css`
  text-align: center;
  font-weight: 300;
  margin-bottom: 20px;

  @media ${breakpoints.fromLrg} {
    text-align: left;
  }
`;

const image = css`
  align-self: center;
  height: 100%;
  width: 100%;

  @media ${breakpoints.fromMed} {
    height: auto;
    width: 450px;
  }
`;

export default { container, heading, caption, image };

import { typography } from "shared";
import styles from "./Member.styles";
import React from "react";

interface Props {
  image: string;
  fullName: string;
  description: string;
  professionalTitle?: string;
}

export const Member: React.FC<Props> = ({
  image,
  fullName,
  description,
  professionalTitle,
}) => {
  return (
    <div css={styles.container}>
      <div css={styles.image}>
        <img
          src={image}
          height={400}
          width={400}
          alt={fullName}
          loading="lazy"
        />
      </div>
      <h3 css={styles.title}>{fullName}</h3>
      <div css={styles.metadata}>
        <p css={typography.body2}>{description}</p>
        {professionalTitle && <p css={typography.body2}>{professionalTitle}</p>}
      </div>
    </div>
  );
};

import { css } from "@emotion/react";
import { breakpoints } from "shared";

const hero = css`
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  opacity: 0;
  margin-top: 80px;
  min-height: 500px;
  display: flex;
  align-items: flex-end;
  background-image: url("images/hero.jpg");
  background-position-y: -50px;
  background-repeat: no-repeat;
  background-size: cover;
  animation: 0.6s fadeIn 0.6s forwards ease-out;

  h4 {
    position: absolute;
    font-style: italic;
    font-weight: 200;
    top: 12px;
    left: 50%;
    width: 60%;
    text-align: center;
    transform: translateX(-50%);
  }

  @media ${breakpoints.fromMed} {
    display: flex;
    align: flex-start;
  }

  @media ${breakpoints.fromLrg} {
    background-position: right 40%;
  }

  transition: background-position-x 0.2s ease-out;
`;

const quote = css`
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  font-weight: 100;
  opacity: 0;
  margin: 20px 0;
  animation: 0.6s fadeIn 0.4s forwards ease-out;
  text-align: center;

  p {
    margin-top: 8px;
    text-align: right;
  }
`;

const rest = css`
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  opacity: 0;
  animation: 0.6s fadeIn 1s forwards ease-out;
`;

export default { hero, quote, rest };

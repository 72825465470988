import { css } from "@emotion/react";
import { typography } from "./typography";

export const defaults = css`
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;1,200&display=swap");

  html {
    box-sizing: border-box;
  }

  body {
    ${typography.body1};
    font-family: "Montserrat";
    font-weight: 300;
    min-height: 100vh;
    padding: 0;
    margin: 0;

    &.is-locked {
      overflow: hidden;
    }
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  button {
    &:focus {
      outline: none;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul {
    margin: 0;
    padding: 0;
  }

  ul,
  ol,
  dl {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    list-style: none;
  }

  h1 {
    ${typography.heading}
  }

  h2 {
    ${typography.subheading1}
  }

  h3 {
    ${typography.subheading2}
  }

  a {
    ${typography.body2}
    color: black;

    &:focus,
    :hover {
      font-weight: 400;
    }
  }
`;

export const globals = { defaults };

import { Global } from "@emotion/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { globals, Layout } from "./shared";
import { Home } from "./views";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Global styles={globals.defaults} />
    <Layout>
      <Home />
    </Layout>
  </React.StrictMode>
);

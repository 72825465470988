import { Member } from "../member";
import styles from "./MemberList.styles";
import React from "react";

export const MemberList: React.FC = () => {
  return (
    <>
      <h2 css={styles.heading}>Naš tim</h2>
      <div css={styles.container}>
        <Member
          image="/images/adela.jpg"
          fullName="Adela Kolobarić"
          professionalTitle="bacc. physioth"
          description="Osnivačica i predsjednica udruge"
        />

        <Member
          image="/images/tena.jpg"
          fullName="Tena Magdić Butković"
          description="Tajnica udruge"
        />

        <Member
          image="/images/volonteri.jpg"
          fullName="Volonteri"
          description="Temelj naše udruge, naši volonteri"
        />
      </div>
    </>
  );
};

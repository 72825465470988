import styles from "./Gallery.styles";
import React from "react";

const images = [
  "/images/gallery/1.jpg",
  "/images/gallery/2.jpg",
  "/images/gallery/3.jpg",
  "/images/gallery/4.jpg",
  "/images/gallery/6.jpg",
  "/images/gallery/7.jpg",
  "/images/gallery/8.jpg",
  "/images/gallery/10.jpg",
  "/images/gallery/11.jpg",
];

export const Gallery: React.FC = () => {
  return (
    <div css={styles.carousel}>
      {images.map((url) => (
        <img
          key={url}
          src={url}
          css={styles.image}
          width={300}
          height={300}
          alt="gallery/1"
          loading="lazy"
        />
      ))}
    </div>
  );
};

import { css } from "@emotion/react";
import { breakpoints } from "./breakpoints";

const page = css`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;

  @media ${breakpoints.fromMed} {
    padding: 0 40px;
  }
`;

export const wrappers = { page };

import React from "react";
import styles from "./Donate.styles";

export const Donate: React.FC = () => {
	return (
		<section css={styles.container}>
			<div css={styles.caption}>
				<h2 css={styles.heading}>Donirajte balu sijena</h2>
				<p>
					Udruga se financira isključivo iz članarina polaznika, što nažalost
					nije u svako doba godine dovoljno kako bi naši konji imali što baciti
					pod zub.
				</p>

				<p>
					Pomozite udruzi u hranjenju naših konja donacijom{" "}
					<strong>5€ za jednu balu</strong> uplatom na račun udruge
				</p>
			</div>

			<img
				loading="lazy"
				css={styles.image}
				src="/images/donacija.png"
				alt="IBAN: HR7824840081135136401"
			/>
		</section>
	);
};

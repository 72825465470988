import { css } from "@emotion/react";
import { breakpoints } from "shared";

const carousel = css`
  margin-top: 100px;
  display: grid;
  grid-gap: calc(var(--unit) * 3);

  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  @media ${breakpoints.fromLrg} {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }
`;

const image = css`
  max-height: 100%;
  width: 100%;
  object-fit: cover;
`;

export default { carousel, image };

import { css } from "@emotion/react";
import { breakpoints } from "./breakpoints";

const medium = css`
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;

  @media ${breakpoints.fromSml} {
    padding: 12px 40px;
  }
`;

const root = css`
  ${medium}
  text-decoration: none;
  background-color: transparent;
  border-width: 0;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  transition: all 0.2s ease-out;
  user-select: none;
  position: relative;
  width: fit-content;

  &:active {
    transition: all 0.1s ease-out;
  }
`;

const ghost = css`
  ${root}
  background-color: transparent;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    transition: width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  }

  &:hover,
  &:focus {
    opacity: 1;
  }

  &:hover:before,
  &:focus:before {
    width: 100%;
    opacity: 1;
    transition: width 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  }
`;

export const button = { ghost };

import styles from "./NavigationMobile.styles";
import { button } from "shared/styles";
import { useState } from "react";
import React from "react";

export const NavigationMobile: React.FC = () => {
  const [popupVisible, setPopupVisible] = useState(false);

  return (
    <>
      <button
        type="button"
        css={button.ghost}
        onClick={() => setPopupVisible(!popupVisible)}
      >
        <img src="icons/hamburger.svg" height={20} width={24} alt="menu" />
      </button>
      {popupVisible && (
        <div css={styles.popup}>
          <div css={styles.popupHeader}>
            <div css={styles.heading}>
              <img src="/icons/logo.png" height={40} width={40} alt="logo" />
              <h1>Udruga &bdquo;4 lista&rdquo;</h1>
            </div>
            <button
              type="button"
              onClick={() => setPopupVisible(false)}
              css={[button.ghost]}
            >
              <img src="icons/close.svg" height={24} width={24} alt="close" />
            </button>
          </div>

          <div css={styles.popupContent}>
            <a
              href="https://www.instagram.com/udruga_4lista/"
              target="_blank"
              rel="noreferrer"
              css={styles.navigationItem}
            >
              <img
                alt="https://www.instagram.com/udruga_4lista/"
                src="icons/instagram.svg"
                height={24}
                width={24}
              />
              Naš Instagram profil
            </a>
            <a
              href="https://www.facebook.com/udruga4lista"
              target="_blank"
              rel="noreferrer"
              css={styles.navigationItem}
            >
              <img
                alt="https://www.facebook.com/udruga4lista"
                src="icons/facebook.svg"
                height={24}
                width={24}
              />
              Naš Facebook profil
            </a>

            <a
              href="mailto:udruga.4lista@gmail.com"
              target="_blank"
              rel="noreferrer"
              css={styles.navigationItem}
            >
              <img
                alt="udruga.4lista@gmail.com"
                src="icons/email.svg"
                height={24}
                width={24}
              />{" "}
              Pošaljite nam e-mail
            </a>
            <a
              href="tel:+385953817277"
              target="_blank"
              rel="noreferrer"
              css={styles.navigationItem}
            >
              <img
                alt="+385953817277"
                src="icons/phone.svg"
                height={20}
                width={20}
              />
              Nazovite nas
            </a>
          </div>
        </div>
      )}
    </>
  );
};

import { SHORT_TITLE, TITLE } from "shared/const";
import { typography } from "shared/styles";
import styles from "./Footer.styles";
import React from "react";

export const Footer: React.FC = () => {
  return (
    <footer css={styles.container}>
      <div css={styles.caption}>
        <p css={typography.heading}>{SHORT_TITLE} i srce blista!</p>
        <p>Udruga prima nove članove, korisnike i volontere!</p>
      </div>
      <div css={styles.social}>
        <a
          href="https://www.instagram.com/udruga_4lista/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="https://www.instagram.com/udruga_4lista/"
            src="icons/instagram.svg"
            height={24}
            width={24}
            loading="lazy"
          />
        </a>
        <a
          href="https://www.facebook.com/udruga4lista"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="https://www.facebook.com/udruga4lista"
            src="icons/facebook.svg"
            height={24}
            width={24}
            loading="lazy"
          />
        </a>

        <a
          href="mailto:udruga.4lista@gmail.com"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="udruga.4lista@gmail.com"
            src="icons/email.svg"
            height={24}
            width={24}
            loading="lazy"
          />
        </a>
      </div>

      <div css={styles.info}>
        <p>{TITLE}</p>
        <p>31000 Osijek</p>
        <a href="tel:+385953817277">+385 95 381 7277</a>
        <a href="mailto:udruga.4lista@gmail.com">udruga.4lista@gmail.com</a>
      </div>
    </footer>
  );
};

import { css } from "@emotion/react";
import { breakpoints, typography } from "shared";

const container = css`
  display: flex;
  flex-direction: column;
  gap: 20px 80px;

  @media ${breakpoints.fromMed} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 80px 80px;
  }
`;

const heading = css`
  ${typography.heading}
  margin-top: 100px;
  margin-bottom: 40px;
  text-align: center;
`;

export default { container, heading };

import { css } from "@emotion/react";
import { breakpoints } from "./breakpoints";

const heading = css`
  font-weight: 300;
  font-size: 28px;
  line-height: 32px;
  transition: font-size 0.2s ease-out;

  @media ${breakpoints.fromMed} {
    font-size: 36px;
    line-height: 40px;
  }
`;

const subheading1 = css`
  font-weight: 200;
  font-size: 24px;
  line-height: 28px;
  transition: font-size 0.2s ease-out;

  @media ${breakpoints.fromMed} {
    font-size: 28px;
    line-height: 32px;
  }
`;

const subheading2 = css`
  font-weight: 200;
  font-size: 20px;
  line-height: 24px;
  transition: font-size 0.2s ease-out;

  @media ${breakpoints.fromMed} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const body1 = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

const body2 = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const typography = { heading, subheading1, subheading2, body1, body2 };

import { css } from "@emotion/react";
import { breakpoints } from "shared";

const container = css``;

const image = css`
  display: flex;
  align-items: center;
  overflow: hidden;

  img {
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  @media ${breakpoints.fromMed} {
    img {
      height: 250px;
      width: 250px;
    }
  }
`;

const title = css`
  margin-top: 20px;
`;

const metadata = css`
  margin-top: 10px;
`;

export default { container, image, metadata, title };

import styles from "./Service.styles";
import React from "react";
import { typography } from "shared";

interface Props {
  image: string;
  title: string;
  description: string;
  price: string;
}

export const Service: React.FC<Props> = ({
  image,
  title,
  description,
  price,
}) => {
  return (
    <div css={styles.container}>
      <div css={styles.image}>
        <img src={image} height={400} width={400} alt={title} loading="eager" />
      </div>
      <h3 css={styles.title}>{title}</h3>
      <div css={styles.metadata}>
        <p css={typography.body2}>{description}</p>
        <p css={typography.body2}>{price}</p>
      </div>
    </div>
  );
};
